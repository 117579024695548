export function printReceipt (receipt, nameDoc) {
  return new Promise((resolve) => {
    var pdfMake = require('pdfmake/build/pdfmake.js')
    if (pdfMake.vfs === undefined) {
      var pdfFonts = require('pdfmake/build/vfs_fonts.js')
      pdfMake.vfs = pdfFonts.pdfMake.vfs
    }
    const productTable = []
    productTable.push(
      [
        {
          text: 'Cantidad',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 0, 0, 0],
          textTransform: 'uppercase',
          fontSize: 10,
        },
        {
          text: 'Descripción del Producto',
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 0, 0, 0],
          textTransform: 'uppercase',
          fontSize: 10,
        },
        {
          text: 'Val. Unit.',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 0, 0, 0],
          textTransform: 'uppercase',
          fontSize: 10,
        },
        {
          text: 'Total',
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 0, 0, 0],
          textTransform: 'uppercase',
          fontSize: 10,
        },
      ],
    )
    for (let i = 0; i < receipt.listaProductos.length; i++) {
      const productArr = [
        {
          text: receipt.listaProductos[i].cantidad,
          border: [false, false, false, true],
          margin: [0, 0, 0, 0],
          alignment: 'left',
          fontSize: 9,
        },
        {
          text: receipt.listaProductos[i].producto.name,
          border: [false, false, false, true],
          margin: [0, 0, 0, 0],
          alignment: 'left',
          fontSize: 9,
        },
        {
          border: [false, false, false, true],
          text: '$' + receipt.listaProductos[i].valorUnitario,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 0, 0, 0],
          fontSize: 9,
        },
        {
          border: [false, false, false, true],
          text: '$' + receipt.listaProductos[i].total,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 0, 0, 0],
          fontSize: 9,
        },
      ]
      productTable.push(productArr)
    }
    const pdfFormat = {
      footer:
        {
          text: 'Recibo: J2RTech S.A.S Comunicarse a los siguientes contactos: \n Teléfono: 0988511884 - Correo: j2rtechsas@gmail.com \n Dirección: Yanahurco 4-91 y Río Upano',
          style: 'notesText',
        },
      content: [
        {
          columns: [
            [
              {
                text: 'RECIBO DE COMPRA',
                color: '#333333',
                width: '*',
                fontSize: 18,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 15],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: nameDoc + ' No.',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 14,
                        alignment: 'right',
                      },
                      {
                        text: receipt.secuencial === undefined ? '' : receipt.secuencial,
                        bold: true,
                        color: '#333333',
                        fontSize: 14,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Fecha de emisión',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 14,
                        alignment: 'right',
                      },
                      {
                        text: receipt.fechaEmision === undefined ? '' : receipt.fechaEmision,
                        bold: true,
                        color: '#333333',
                        fontSize: 14,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: 'Usuario',
              color: '#aaaaab',
              bold: true,
              fontSize: 10,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              text: 'Proveedor',
              color: '#aaaaab',
              bold: true,
              fontSize: 10,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              text: receipt.usuario,
              bold: true,
              color: '#333333',
              alignment: 'left',
              fontSize: 10,
            },
            {
              text: receipt.proveedor.completeData,
              bold: true,
              color: '#333333',
              alignment: 'left',
              fontSize: 10,
            },
          ],
        },
        '\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              if (i === 1 || i === 0) {
                return '#bfdde8'
              }
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 2
            },
            paddingBottom: function (i, node) {
              return 2
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['15%', '55%', '15%', '15%'],
            body: productTable,
          },
        },
        '\n',
        {
          text: 'OBSERVACIONES: ' + receipt.observaciones,
          style: 'obseText',
        },
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 3
            },
            paddingBottom: function (i, node) {
              return 3
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Transporte:',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                },
                {
                  border: [false, true, false, true],
                  text: '$' + receipt.transporte,
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                },
              ],
            ],
          },
        },
        '\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 3
            },
            paddingBottom: function (i, node) {
              return 3
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'GRAN TOTAL:',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                },
                {
                  border: [false, true, false, true],
                  text: '$' + receipt.total,
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 0, 0, 0],
                  fontSize: 10,
                },
              ],
            ],
          },
        },
        '\n',
        '\n',
        '\n',
        '\n',
        '\n',
        {
          text: '____________________________________',
          style: 'obseText',
        },
        {
          text: 'CC. ',
          style: 'obseText',
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 8,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 7,
          margin: [10, 10, 10, 10],
        },
        obseText: {
          fontSize: 10,
          margin: [10, 10, 10, 10],
        },
      },
      defaultStyle: {
        columnGap: 20,
        // font: 'Quicksand',
      },
    }
    var docDefinition = pdfFormat
    pdfMake.createPdf(docDefinition).download(receipt.numsecuencial === undefined ? nameDoc + '.pdf' : nameDoc + '-' + receipt.numsecuencial + '.pdf')
    resolve(true)
  })
}
