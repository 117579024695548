<template lang="pug">
  v-container#receipts(fluid='' tag='section')
    v-dialog(v-model='confirmationDialogReceipt' persistent max-width='290')
      v-card
        v-card-title.text-h5
          | Confirmar
        v-card-text ¿Estás seguro de anular este registro?
        v-card-actions
          v-spacer
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='confirmationDialogReceipt = false')
              | Cancelar
            v-btn.success-btn(text='' @click='anulReceipt' :loading="loadingSave")
              | Aceptar
    v-dialog(fullscreen="" v-model='dialogReceipt')
      v-card
        receipt-modal(@closeReceiptModal="closeReceiptModal" :receiptToEdit="receiptToEdit")
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    base-material-card.px-5.py-3(icon='mdi-clipboard-arrow-down' title='Lista de Recibos' style='min-height: 100vh;')
      v-container#billing-table(fluid='' tag='section')
        v-form(ref='formAddSecuencialReceipt' v-model='validAddSecuencialReceipt' lazy-validation='')
          v-row
            v-col(cols='10' md='3')
              v-text-field.purple-input(:rules="requeridos" v-model="receiptSequentialVal" label='Secuencial de recibo' type='number' maxlength="50")
            v-col(cols='2' md='2')
              v-tooltip(bottom="")
                template(v-slot:activator="{ on: tooltip }")
                  v-btn(icon="" color="primary" v-on="{...tooltip, ...menu}" @click="save" :loading="loadingSaveEdit")
                    v-icon(style="font-size: 30px;") mdi-content-save
                span.tooltips Guardar Secuencial
            v-col(cols="12" md="3")
              v-menu(ref='menuFromNote' v-model='menuFromNote' :close-on-content-click='false' :return-value.sync='dateFromReceipt' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateFromReceipt' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuFromNote.save(dateFromReceipt)' v-model='dateFromReceipt' no-title='' scrollable='' :max="dateUntilReceipt" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
            v-col(cols="10" md="3")
              v-menu(ref='menuUntilNote' v-model='menuUntilNote' :close-on-content-click='false' :return-value.sync='dateUntilReceipt' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='dateUntilReceipt' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(@change='$refs.menuUntilNote.save(dateUntilReceipt)' v-model='dateUntilReceipt' no-title='' scrollable='' :min="dateFromReceipt" style='margin:0px;' color="green lighten-1")
            v-col(cols="2" md="1")
              v-tooltip(bottom='')
                template(v-slot:activator='{ attrs, on }')
                  v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDateNote")
                    v-icon(style="font-size: 30px; color: white;") mdi-magnify
                span.tooltips FILTRAR POR FECHA
            v-col(cols="12" md="6" style="margin-bottom: 20px; padding-top: 0px;")
              v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
            v-col(cols="12" md="3" style="padding-top: 0px;")
              v-btn.mr-0(color='primary' rounded='' @click='addNewReceipt'  style="width: 100%; margin-top: 7px;")
                strong
                  | NUEVO
                  br
                  | RECIBO
            v-col(cols="12" md="3" style="padding-top: 0px;")
              v-btn.mr-0(color='secondary' rounded='' style="width: 100%; margin-top: 7px;" @click='downLoadExcelFileReceipt' )
                strong
                  | DESCARGAR
                  br
                  | LISTA
            v-skeleton-loader(style="width: 100%;" :loading="cargando" type="table" v-if="firstLoad")
            v-data-table.mx-2#tablaDatosRecibos(style="width: 100%;" :headers="headersReceipt" v-show="!firstLoad" :items="receiptsLst" item-key="id" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Recibos por página'\}")
              template(v-slot:item.estado="{ item }")
                v-chip(:color="getColor(item.estado)" small label) {{ item.estado ? item.estado : 'ACTIVO' }}
              template(v-slot:item.action="{ item }")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='green' v-on="on" @click="editReceipt(item)")
                      v-icon(small) mdi-eye
                  span.tooltips Visualizar Recibo
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="item.estado === 'ANULADO'" text="" icon="" color='red' v-on="on" @click="openAnulReceipt(item)")
                      v-icon(small) mdi-trash-can-outline
                  span.tooltips Anular Recibo
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='brown' v-on="on" @click="printReceipt(item)" :loading="loadingPrintReceipt")
                      v-icon(small) mdi-printer
                  span.tooltips Imprimir Recibo
              v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                | Sin resultados
              v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                | Sin datos
              v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import moment from 'moment'
  import * as printerReceipt from '../../../functions/printerReceipt.js'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      ReceiptModal: () => import('@/views/dashboard/component/application/ReceiptsModal'),
    },
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      confirmationDialogReceipt: false,
      receiptPurchaseSelected: null,
      loadingPrintReceipt: false,
      validAddSecuencialReceipt: true,
      receiptToEdit: {
        usuario: '',
        secuencial: '',
        fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
        proveedor: null,
        listaProductos: [],
        observaciones: '',
        total: '',
        transporte: '',
        horaCreado: null,
        advanceSelected: null,
        payAdvance: '',
      },
      dialogReceipt: false,
      ordenarPor: '',
      headersReceipt: [
        { text: 'N° Recibo', value: 'secuencial', align: 'left', filterable: true },
        { text: 'Fecha de Emisión', value: 'fechaEmision', align: 'left', filterable: true },
        { text: 'Proveedor', value: 'proveedor.completeData', align: 'left', filterable: true },
        { text: 'Usuario', value: 'usuario', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
        { text: 'ESTADO', value: 'estado', align: 'left', filterable: true },
        { text: 'ACCIONES', align: 'center', value: 'action', sortable: false },
      ],
      cargando: false,
      firstLoad: false,
      search: '',
      menuFromNote: false,
      menuUntilNote: false,
      menu: false,
      dateUntilReceipt: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFromReceipt: moment(new Date()).format('YYYY-MM-DD'),
      loadingSave: false,
      loadingSaveEdit: false,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      receiptSequentialVal: '0',
      banSendReceiptPurchase: '0',
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      receiptsLst () {
        return this.$store.state.receipt.lstRecibos
      },
      receiptSequential () {
        return this.$store.state.receipt.secuenciaRecibo
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
      receiptSequential () {
        this.receiptSequentialVal = this.receiptSequential
      },
    },
    methods: {
      getColor (valor) {
        if (valor !== 'ANULADO') {
          return 'green'
        } else {
          return 'red'
        }
      },
      anulReceipt () {
        if (this.banSendReceiptPurchase === '0') {
          this.banSendReceiptPurchase = '1'
          setTimeout(() => {
            this.banSendReceiptPurchase = '0'
          }, 1000)
          this.loadingSave = true
          this.receiptPurchaseSelected.estado = 'ANULADO'
          this.$store.dispatch('receipt/anulReceipt', this.receiptPurchaseSelected).then(res => {
            this.loadingSave = false
            this.confirmationDialogReceipt = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Recibo anulado de manera correcta.',
            }
          }, reject => {
            this.loadingSave = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de anular el recibo.',
            }
          })
        }
      },
      editReceipt (item) {
        this.dialogReceipt = true
        this.receiptToEdit = item
      },
      openAnulReceipt (item) {
        this.receiptPurchaseSelected = item
        this.confirmationDialogReceipt = true
      },
      printReceipt (item) {
        this.loadingPrintReceipt = true
        item.secuencial = item.secuencial.toString().padStart(9, '0')
        printerReceipt.printReceipt(item, 'Recibo-' + item.secuencial).then(res => {
          this.loadingPrintReceipt = false
        })
      },
      save () {
        if (this.$refs.formAddSecuencialReceipt.validate()) {
          this.loadingSaveEdit = true
          this.$store.dispatch('receipt/setSecuencial', this.receiptSequentialVal).then(res => {
            this.loadingSaveEdit = false
            if (res) {
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Secuencial agregado de manera correcta.',
              }
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de agregar el secuencial.',
              }
            }
          }).catch(e => {
            this.loadingSaveEdit = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de agregar el secuencial.',
            }
          })
        }
      },
      filterByDateNote () {
        const data = {
          firstDay: this.dateFromReceipt,
          lastDay: this.dateUntilReceipt,
        }
        this.$store.dispatch('receipt/loadReceiptsFilter', data)
      },
      addNewReceipt () {
        this.receiptToEdit = {
          usuario: '',
          secuencial: '',
          fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
          proveedor: null,
          listaProductos: [],
          observaciones: '',
          total: '',
          transporte: '',
          horaCreado: null,
          advanceSelected: null,
          payAdvance: '',
        }
        this.dialogReceipt = true
      },
      downLoadExcelFileReceipt () {
        const data = []
        if (this.receiptsLst) {
          for (let i = 0; i < this.receiptsLst.length; i++) {
            data.push({
              'Fecha de Emisión': this.receiptsLst[i].fechaEmision,
              Proveedor: this.receiptsLst[i].proveedor.completeData,
              // Cliente: this.receiptsLst[i].client.completeData,
              Decripción: this.receiptsLst[i].descripcion,
              // IVA: this.receiptsLst[i].iva12,
              Total: this.receiptsLst[i].total.replace('.', ','),
            })
          }
          functions.JSONToCSVConvertor(data, 'Recibos', true)
        }
      },
      closeReceiptModal () {
        this.dialogReceipt = false
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
      this.receiptSequentialVal = this.receiptSequential
    },
  }
</script>
